

// @import 'node_modules/ngx-bar-rating/themes/br-default-theme.css';
// @import 'node_modules/ngx-bar-rating/themes/br-horizontal-theme.css';

@import "node_modules/datatables.net-dt/css/jquery.dataTables.css";
@import "node_modules/datatables.net-buttons-dt/css/buttons.dataTables.css";

// @import "node_modules/ic-datepicker/themes/theme-default.css";

// @import "node_modules/ngx-bar-rating/themes/br-default-theme.css";
// @import "node_modules/ngx-bar-rating/themes/br-bootstrap-theme.css";
// @import "node_modules/ngx-bar-rating/themes/br-fontawesome-theme.css";
// @import "node_modules/ngx-bar-rating/themes/br-fontawesome-o-theme.css";
// @import "node_modules/ngx-bar-rating/themes/br-horizontal-theme.css";
// @import "node_modules/ngx-bar-rating/themes/br-vertical-theme.css";
// @import "node_modules/ngx-bar-rating/themes/br-stars-theme.css";
// @import "node_modules/ngx-bar-rating/themes/br-movie-theme.css";
// @import "node_modules/ngx-bar-rating/themes/br-square-theme.css";


@import 'node_modules/sweetalert2/src/sweetalert2.scss';

@import "range-slidar";
@import "ng2-archwizard";

.full-data-search {
  margin-left: 0.5em;
  display: inline-block;
  width: auto !important;
}

.full-data-show-entry {
  width: 75px;
  display: inline-block;
}

.full-data-table {
  .glyphicon {
    font-family: 'IcoFont', sans-serif;
    position: absolute;
    right: 14px;
    font-size: 20px;
    top: 9px;
    &.glyphicon-triangle-bottom:before {
      content: "\eb53";
    }
    &.glyphicon-triangle-top:before {
      content: "\eb56";
    }
  }
  th {
    position: relative;
  }
}

