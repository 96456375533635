/* You can add global styles to this file, and also import other style files */

/* SCSS */
// regular style toast
@import "scss/style";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import 'ngx-toastr/toastr';

// * {
//     border: 1px solid red;
// }
:root{
    --font-extralight:200;
    --font-light:300;
    --font-regular:400;
    --font-medium:500;
    --font-semibold:600;
    --font-bold:700;
    --primary-color:#006736;
    --error-color:#ff0000;
    --w3m-accent-color: #006736 !important;
    --new-ui-light-bg: #EFEFEF;
    --scrollbar-default:#F2F2F7;
    --scrollthumb:#D1D1D6;
    --secondary-color:#232323;
}
*{
    margin: 0;
    padding: 0;
}
body{
  background: #efefef !important;
  font-family: 'Source Code Pro', monospace !important;
}
#toast-container > div {
    opacity:1 !important;
}
.btn-primary {
    background-color: #ffffff !important;
    border-color: #ffffff !important;
    color: white !important;
}

.btn-primary-outline {
    background-color: #ffffff !important;
    border-color: #ffffff !important;
    color: #6c6d6e;
}

.btn-primary-outline:hover {
    background-color: #ffffff !important;
    border-color: #ffffff !important;
    color: #ffffff
}

time-picker > div {
    z-index: 10000 !important;
}

.tag-chips{
    border: none;
    border-bottom: 1px solid #ccc;
    background: none;
    padding: 0;
}

tag-input-form > form > input {
    padding: 0 !important;
}
    input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #adb5bd !important;
        opacity: 1 !important; /* Firefox */
    }
    
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #adb5bd !important;
    }
    
    ::-ms-input-placeholder { /* Microsoft Edge */
        color: #adb5bd !important;
  
}
.text-green{
  color: #006736 !important;  
}

.btn-green:hover{
  background: rgba(0, 103, 54,1) !important;
  transition: all .5s ease-in-out;
  color: #fff;
}
aw-wizard-navigation-bar.horizontal.large-filled-symbols ul.steps-indicator li.done .step-indicator{
    background-color: #006736 !important;
    pointer-events: none;
    cursor: default;
}
.arc-wizard .large-filled-symbols ul.steps-indicator li.current:after, .arc-wizard .large-filled-symbols ul.steps-indicator li.editing:after{
    background-color: #000 !important;
}
.toast-error {
    background-color: #BD362F !important;
}
.toast-success {
    background-color: #51A351 !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}

label {
    margin-bottom: 0;
}
// ::-webkit-input-placeholder { 
//    padding: 0px 5px;
//    font-weight: 500;
// }
  
// :-ms-input-placeholder {
//     padding: 0px 5px;
//     font-weight: 500;
// }

// ::placeholder {
//     padding: 0px 5px;
//     font-weight: 500;
// }
.ng2-tag-input .ng2-tags-container{
    gap:10px;
    justify-content: flex-start;
}

.ng2-tag-input__text-input{
  width: 185px;
  font-family: inherit !important;
}
form{
    margin-inline-end: 0px !important;
}
.gap-2{
    column-gap: 15px !important;
} 
.btn-group{
    color: #000;
    border-radius: 20px;
    padding: 5px 10px;
  }
  .button{
    padding: 8px 16px;
    font-size:14px;
    line-height: 20px;
    font-weight: 500;
    letter-spacing: .4px;
    border-radius: 30px;
    background:  transparent;
  }
  
  .btn-group label{
  margin: 0;
  }
  .text-right{
    text-align: right !important;
  }
  .sidebarview{
    position: absolute;
    top: 85px;
    left: 0;
    background: #fff;
    width: 310px;
    min-height: 100vh;
    height: 100%;
    transition:0s ease-in-out;
  }
  .section{
    position: relative;
    width: calc(100% - 350px);
    left: 330px;
  }
  
  .sidebarview :hover .nav_name{
    display: block;
  }
  .sidebarview:hover .nav_names{
    display: block;
  }
  .nav-link.active, .nav-link:focus{
    background: var(--primary-color);
    color: #fff;
    width: 100%;
  }
  .nav-link.active .nav_name{
    color: #fff !important;
  }
  .nav-link:hover{
    background: rgba(0, 103, 54,.15);
    color: var(--primary-color) !important;
  }
  .nav-link.active:hover{
    background: #006736;
  }
  .copyfile{
    color: #707070;
    transition: .2s all ease-in-out;
  }
  .copyfile:hover{
    color: #006736;
    transition: .2s all ease-in-out;
    transform: translateX(5px);
  }
  .main-wrap{
    padding: 125px 0px 50px;
    position: relative;
    //height: calc(100% - 140px);
    // height: 100%;
  }
  .nav-link>.nav_name{
    color: #232323;
  }
  .manage-collection{
    list-style: none;
    padding: 0 0;
    margin: 0;
    border-bottom:1px solid #D1D1D6;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .manage-collection .nav-item {
    padding: 10px 25px 15px 15px;
    font-size:18px;
    line-height: 24px;
    color: #AEAEB2;
    font-weight: var(--font-medium);
    letter-spacing: -0.45px;
    display: inline-block;
    border:4px solid transparent;
    cursor: default;
  }

  .manage-collection .nav-item:hover{
    color: var(--primary-color) !important;
  }
  .submit-btn, .clear-btn{
    font-size:18px;
    font-weight: var(--font-semibold);
    letter-spacing: -0.45px;
    background:var(--primary-color);
    border: 1px solid var(--primary-color);
    border-radius: 12px;
    padding: 14px 36px;
    color: #fff;
    transition: .5s all ease-in-out;
}
.submit-btn:hover, .submit-btn:focus{
   background:rgba(0, 103, 54, 0.08);
    color: var(--primary-color);
    transition: .5s all ease-in-out;
}
.clear-btn{
    background:rgba(0, 103, 54, 0.1);
    color: var(--primary-color);
}
.clear-btn:hover, .clear-btn:focus{
    background:rgba(0, 103, 54, 1);
    color: #fff;
    transition: .5s all ease-in-out;
}
.managecollection{
  background: #fff;
  padding: 20px;
  border-radius: 16px;
}

.backbtn{
  background: rgba(229, 229, 234, 1);
  padding: 10px 18px;
  border-radius: 5px;
}
a.back-btn span{
  font-size:20px;
  line-height: 32px;
  font-weight: var(--font-semibold);
  color: rgba(17, 24, 39, 1);
  padding: 0;
  margin: 0;
}
a{
  text-decoration: none !important;
}
.blockuser{
  padding: 9px 26px;
  border: 1px solid var(--primary-color);
  background: var(--primary-color);
  color: #fff;
  border-radius: 12px;
  font-size:16px;
  font-weight: var(--font-bold);
  letter-spacing: -0.8px;
}
.profile-view{
  background: #f9f9f9;
  padding: 24px;
  border-radius:12px
}
.profile-view-img{
  width:100px;
  height: 100px;
  object-fit: cover;
  border-radius: 100%;
  margin: 0 auto;
}

.profile-user-details h3{
  font-size: 20px;
  line-height: 28px;
  font-weight: var(--font-bold);
  color: #111827;
  padding: 0;
  margin-bottom: 10px;
  text-align: center;
}
.profile-user-details h4{
    font-size: 14px;
    line-height: 22px;
    font-weight: var(--font-regular);
    color: #687588;
    padding: 0;
    text-align: center;
    margin-bottom: 24px;
}
.profile-user-details h5{
  font-size:10px;
  letter-spacing: -0.25px;
  font-weight: var(--font-medium);
  color: #8E8E93;
  margin: 0;
  padding: 0;
}
.profile-user-details p{
  font-size:14px;
  line-height: 22px;
  letter-spacing: -0.35px;
  font-weight: var(--font-medium);
  color: rgba(0, 122, 255, 1);
  margin: 0;
  overflow: hidden;
}
.last-p p{
  color: rgba(44, 44, 46, 1);
}
.my-4 hr{
  margin: 0px 0px;
}
.user-active{
  background: rgba(0, 103, 54, 0.12);
  border: 1px solid var(--primary-color);
  border-radius: 12px;
  color: var(--primary-color);
  font-size:10px;
  line-height: 14px;
  letter-spacing: .2px;
  font-weight: var(--font-bold);
  width: 100%;
  padding: 12px 20px;
  text-transform: uppercase   ;
}
.previous-btn, .next-btn{
  padding: 7px 12px;
  background: #fff;
  border: 1px solid transparent;
  font-size:14px;
  line-height: 18px;
  letter-spacing: .5px;
  color: rgba(44, 44, 46, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  gap: 8px;
  transition: 0.3s ease-in-out;
}
.previous-btn:hover, .next-btn:hover, .previous-btn:focus, .next-btn:focus{
  border: 1px solid var(--primary-color);
  background: var(--primary-color);
  color: #fff ;
}
.previous-btn > span, .next-btn > span {
  color: var(--primary-color);
}
.previous-btn:hover span, .next-btn:hover  span{
  color: var(--white);
}
.pagination .page-item{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 8px;
  gap: 10px;
  border-radius: 5px;
}
.pagination.pagination .page-link{
  border: 0;
  font-size: 14px;
  letter-spacing: .4px;
  color: rgba(44, 44, 46, 1);
  font-weight: var(--font-regular);
  border-radius: 5px !important;
}
.pagination .page-link.active, .page-link:hover, .page-link:focus{
  background: var(--primary-color);
  color: #fff;
  box-shadow: none;
}
.viewall{
  font-size:12px;
  line-height: 16px;
  letter-spacing: -0.2px;
  color:rgba(0, 103, 54, 1);
  font-weight: var(--font-medium);
  padding: 0;
  margin: 0;
  text-decoration: none;
}
.salebtn{
  border: 1px solid #e5e5ea;
  background: #f2f2f7;
  padding: 4px 5px;
  display: block;
  border-radius: 5px;
  font-size: 8px;
  line-height: 16px;
  text-align: center;
  letter-spacing: -0.2px;
  font-weight: var(--font-regular);
  color: #2C2C2E;
  text-decoration: none;
  margin: 0 10px;
  white-space: nowrap;
  max-width:150px
}

.tooltips{
  position: relative;
}
.loan-details-tooltip:before{
  content:'';
  position: absolute;
  top: -5px;
  left: 10px;
  width: 10px;
  height: 10px;
  background: #232323;
  transform: rotate(45deg);
  border-width: 1px 0 0 1px;
  border-style: solid;
  border-color: #232323; 
}
.loan-details-tooltip{
  position: absolute;
  top: 35px !important;
  left: 0;
  background:#232323;
  border: 1px solid #232323;
  border-radius: 8px;
  width: 220px;
  z-index: 1052 !important;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: #ffffff;
  padding: 5px 10px;
  display: none;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  word-break: break-word;
}
// .loan-details-tooltip{
//   top:35px !important;
//   text-transform: initial;
//   white-space: break-spaces;
// }
.tooltips:hover .loan-details-tooltip{
  transition-delay: 2s;
  display: block !important;

}
.tabs-btns {
    list-style: none;
    padding: 0;
    margin: 0;
    border-bottom: 1px solid #D1D1D6;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  & li {
    padding: 10px 25px 15px 15px;
    font-size: 18px;
    line-height: 24px;
    color: #aeaeb2;
    font-weight: var(--font-regular);
    letter-spacing: -.45px;
    display: inline-block;
    border: 4px solid transparent;
    cursor: pointer;
    &:hover {
      color: var(--primary-color) !important;
    }
  }
  & .active {
    border-bottom: 4px solid var(--primary-color) !important;
    color: var(--primary-color) !important;
    font-weight: var(--font-medium);
  }
}
.warning-text {
  font-size: 12px;
  color: #eb6565;
}

@media(max-width:1100px){
  .submit-btn {
      font-size:16px
  }
}

@media(max-width:991px){
  .main-wrap{
    height: auto;
  }
  .sidebarview{
    width:100px;
  }
  .section {
    position: relative;
    width: calc(100% - 100px);
    left: 100px;
  }
  .sidebarview:hover  {
    width: 310px !important;
    // z-index: 9999;
  }
  // .nav_name{
  //   display: none;
  // }
  .sidebarview:hover .nav_name{
    display: block;
  }
  .managecollection {
    padding: 10px;
  }
}
@media(max-width:767px){
  .submit-btn, .clear-btn{
      font-size:14px;
      padding: 14px 20px;
  }
  .manage-collection .nav-item {
    padding: 10px 15px 15px 15px;
    font-size: 16px;
    line-height: 20px;
  }
  .profile-user-details h3 {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 5px;
  }
  .base_footer{
    display: none;
  }
}
@media(max-width:575px){
  .borrow-submenus .nav-item {
    padding: 10px 0px 10px;
    font-size: 14px;
    line-height: 20px;
  }
  .sidebarview {
    width: 70px;
  }   
  .bannerimg{
      display: none;
  }
  .section {
    position: relative;
    width: calc(100% - 70px);
    left: 70px;
  }
  .manage-collection .nav-item {
    padding: 10px 15px 15px 0;
    font-size: 12px;
    line-height: 16px;
  }
 .profile-view{
    padding: 10px;
 }
 .blockuser{
    font-size:14px;
 }
}
.modal-dialog {
  pointer-events: all  !important;
}
/**sweet alert box for user confirmation*/
.swal2-backdrop-show{
  background:rgba(0,0,0,.5) !important
}
.swal2-popup .swal2-header {
  flex-direction: unset !important;
}
.swal2-popup .swal2-title {
  font-size: 20px !important;
  line-height: 30px !important;
}
.swal2-popup .swal2-close {
  right: 2px !important;
  font-size: 28px !important;
}
.swal2-popup .swal2-styled.swal2-confirm{
  background-color: rgb(0, 103, 54) !important;
}

.swal2-popup .swal2-styled:focus {
  background-color: rgba(0, 103, 54,.8) !important;
  outline: 0;
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px rgba(0, 103, 54,.4) !important;
}
.swal2-popup {
  padding: 10px 20px !important;
}
.managecollection .form-control::placeholder, .managecollection .form-control::-moz-placeholder,.managecollection .form-control::-webkit-input-placeholder{
  font-size:16px !important;
  line-height: 22px !important;
  font-weight: 400 !important;
}
.wallet-btn{
  padding: 5px 18px !important;
  font-size: 14px !important;
}
.tag__text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 30px;
  font-size: 16px;
  padding: 0px 5px;
}
@media(min-width:1199px){
  .main-wrap{
    min-height: calc(100% - 140px);
  }
  
}
@media(max-width:575px){
  .managecollection .form-control::placeholder, .managecollection .form-control::-moz-placeholder,.managecollection .form-control::-webkit-input-placeholder{
    font-size: 8px !important;
    line-height: 12px !important;
  }
}

.toast-container{
  position: fixed !important;
}
.date-section{
  display: flex;
  align-items: center;
}
.date-section > span{
  border-radius: 5px;
  padding: 2px 10px;
  display: block;
  text-align: center;
  border: 1px solid rgba(0, 103, 54, 0.15);
  background: rgba(0, 103, 54, 0.08);
  font-size: 10px;
  line-height: 16px;
  letter-spacing: -0.2px;
  font-weight: var(--font-regular);
  color: #2C2C2E;
  text-decoration: none;
  margin: 0 5px;
}

:disabled{
  background-color: #808080 !important;
  border-color: #808080 !important;
  color: #ffffff !important;
}
.user-email-text{
  font-size: 12px !important;
}

.swal2-popup .swal2-actions.swal2-loading .swal2-styled.swal2-confirm{
  border: 0.5em solid #808080 !important;
  border-left-color: rgb(0, 103, 54) !important;
  width: 3.5em !important;
  height: 3.5em !important;
}
.pcoded-header .m-header{
  width: 100% !important;
}
.pcoded-header .ml-auto{
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
  gap: 1rem;
}
.pcoded-header{
  top: 15px !important;
}

.sidebarview{
  position: fixed;
  top: 65px;
  left: 0;
  background: #fff;
  width: 310px;
  min-height: 100vh;
  height: 100vh;
  transition:0s ease-in-out;
}
.section {
  position: relative;
  width: calc(100% - 350px);
  left: 330px;
  top: 7rem;
  height: 100vh;
}
.bg-grey{
  padding: 10px 0px;
  border-bottom: 1px solid #D1D1D6;
  box-shadow: rgba(241, 245, 249, 0.1) 0px 2px 3px;
  background: #f9f9f9;
}
.custom-file-label {
  margin-top: 2rem;
}
.co-padding{
  padding: 50px 0px 0px;
}
.h-40 {
  height: 10rem;
}

.w-40 {
  width: 10rem;
}




.full-height {
  height: 100vh;
}

.nowrap {
  white-space: nowrap;
}

.text-notes {
  font-size: 12px;
  line-height: 26px;
  color: #707070;
}

/**borrow and lend**/
.currency_list {
  list-style: none;
  padding: 0px;
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 16px;
  row-gap: 5px;
}

.currency_list li {
  border: 1px solid transparent;
  padding: 3px 15px;
  border-radius: 5px;
  font-size: 16px;
  line-height: 26px;
  margin: 0 0 10px;
  background: rgba(0, 103, 54,.1);
}
.form-control:focus {
  border-bottom-color: transparent;
  background-size: 100% 100%, 100% 100%;
  transition-duration: 0.3s;
  box-shadow: none;
  background-image: linear-gradient(to top, #006736 2px, rgba(70, 128, 255, 0) 2px), linear-gradient(to top, #ced4da 1px, rgba(206, 212, 218, 0) 1px) !important;
}


.select {
  background: #ddd;
}

.fw-500 {
  font-weight: 500;
}

p {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: #212121;
  margin: 0;
  padding: 0;
}

.borrow_lend h5 {
  margin-bottom: 0;
}

.cancel-button {
  color: #333;
  transition: color 0.3s;
}

.cancel-button:hover {
  color: rgb(244, 63, 94);
  cursor: pointer;
}


.onboard_copy{
  padding: 5px 5px;
  background: #006736;
  border: 1px solid transparent;
  border-radius: 10px;
  height: 30px;
  cursor: pointer;
}
.onboard_copy>img{
  width: 16px;
  height: 16px;
  object-fit: contain;
  position: relative;
  top: -2px;
}
.delete_btn_right{
  position: absolute;
  top: 10px;
  right: 20px;
}
.deletecollection_right {
  position: absolute;
  top: 1%;
  right: 20px;
  transform: translateY(-1%);
  width: 100%;
}
.btn-success{
  background: #006736 !important;
  border: #006736 !important;
  color: #fff;
}
.btns-groups{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.posrelative{
  position: relative;
}
.icon_pos{
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #006736;
  border-color: #006736;
  border-radius: 0;
}
li.page-item {
  border: transparent;
}

.page-item .page-link {
  border-radius: 0;
  margin: 0 0px;
}
.page-link:hover {
  z-index: 2;
  color: #fff;
  text-decoration: none;
  background-color: rgba(0, 103, 54,.5);
  border-color: rgba(0, 103, 54,.5);
}
.page-link {color: #006736;}
.page-item:first-child .page-link, .page-item:last-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

@media(max-width:575px) {
  .currency_list li {
      font-size: 14px;
      line-height: 20px;
  }
}

@media(max-width:575px) {
  .borrow_lend h5 {
      margin-bottom: 15px;
  }
}

#collectionTab input[type=search] {
  width: 25rem;
}
.search-text{
  float: right;
  width: 15%;
  margin-bottom: 1rem;
  border: 1px solid #00673a;
  box-shadow: 1px solid #00673a;
  border-radius: 10px;

}
.search-section{
  width: 100%;
}
.position-abs {
  position: absolute;
  top:-70px;
  right: 10px;
  gap: 1rem;
  display: flex;
  white-space: nowrap;
}
.bg-green{
  background: #94e3c0 !important;
}
.cursor-pointer{
  cursor: pointer !important;
}
.spinner-border{
  border-right-color: #007036 !important;
}


.tab-menu .nav-item {
  padding: 10px 25px 15px 15px;
  font-size:18px;
  line-height: 24px;
  color: #AEAEB2;
  font-weight: var(--font-medium);
  letter-spacing: -0.45px;
  display: inline-block;
  border:4px solid transparent;
  cursor: pointer;
}

.nav-item.menuactivities{
  border-bottom: 4px solid var(--primary-color) !important;
  color: var(--primary-color) !important;
  font-weight: var(--font-semibold);
}
.tab-menu .nav-item:hover{
  color: var(--primary-color) !important;
}
/** stt**/
.table thead{
  background: #E5E7EB;
}
.table thead th {
  border-bottom: 1px solid #E5E5EA;
  font-size: 14px;
  color: #2c2c2e;
  text-transform: none;
  font-weight: 500;
  padding: 10px 20px;
  vertical-align: middle;
}
.table  td{
  padding: 10px 20px;
  vertical-align: middle;
  color: #232323;
  font-weight: 400;
}
.btn.btn-danger{
  color: #fff;
  background: rgba(244, 63, 94,1);
  border-color: transparent;
  font-weight: 500;
  border-radius: 12px;
  padding: 7px 12px;
}
.btn.btn-light{
  font-weight: 500;
  border-radius: 12px;
  padding: 7px 12px;
}

.disabled-btn{
  cursor: not-allowed !important;
  opacity: 1 !important;
  background: #D1D1D6 !important;
  color: #232323 !important;
  font-weight: 500;
  border-radius: 12px;
  padding: 7px 12px;
}
.btn.btn-warning{
  color: #fff;
  background: rgba(0, 103, 54,.8);
  font-weight: 500;
  border-radius: 12px;
  padding: 7px 12px;
  border-color: transparent;
}

.btn-green{
  background: rgba(0, 103, 54,.8) !important;
  border: #006736 !important;
  color: #fff !important;
  transition: all .5s ease-in-out;
  display: flex;
  align-items: center;
  padding: 7px 12px;
  border-radius: 10px;
}
.btn.btn-warning:hover, .btn.btn-warning:focus{
  border: 1px solid transparent !important;
  background: rgb(0, 103, 54) !important;
}
.btn.btn-danger:hover,.btn.btn-danger:focus{
  background: rgb(244, 63, 94);
}
.position-relative{
  position: relative;
}
.edit-tooltip{
  position: absolute;
  top: 40px !important;
  left: 0;
  background:#232323;
  border: 1px solid #232323;
  border-radius: 8px;
  width: 70px;
  z-index: 1052 !important;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: #ffffff;
  padding: 5px 10px;
  text-align: center;
  display: none;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  word-break: break-word;
}
.edit-tooltip:before{
  content:'';
  position: absolute;
  top: -5px;
  left: 15px;
  width: 10px;
  height: 10px;
  background: #232323;
  transform: rotate(45deg);
  border-width: 1px 0 0 1px;
  border-style: solid;
  border-color: #232323;
}
.reset-tooltip{
  position: absolute;
  top: 35px !important;
  right: 0;
  left: auto;
  background:#232323;
  border: 1px solid #232323;
  border-radius: 8px;
  width: fit-content;
  z-index: 1052 !important;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: #ffffff;
  padding: 5px 10px;
  display: none;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  word-break: break-word;
}
.reset-tooltip:before{
  content:'';
  position: absolute;
  top: -5px;
  right: 12px;
  width: 10px;
  height: 10px;
  background: #232323;
  transform: rotate(45deg);
  border-width: 1px 0 0 1px;
  border-style: solid;
  border-color: #232323;
}
.position-relative:hover .reset-tooltip{
  display: block;
}
.position-relative:hover .edit-tooltip{
  display: block;
}


.btn-close {
  border: none !important;
  padding: 0px 5px !important;
  border-radius: 5px !important;
  color: #232323;
  cursor: pointer;
  transition: all ease-in-out .5s;
}
.btn-close:hover{
  transition: all ease-in-out .5s;
  color: rgb(244, 63, 94);
}
@media(max-width:575px) {
  .tab-menu .nav-item {
      padding: 10px 15px 15px 0;
      font-size: 12px;
      line-height: 16px;
  }
}
.me-3 {
  margin-right: 1rem !important;
}
.ms-3 {
  margin-left: 1rem !important;
}
.loader-wrap{
  display: flex;
  align-items: center;
  justify-content: center;
  & .btn-green {
    padding-right: 24px;
  }
}
.Loaders{
  position: relative;
  left: -9999px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  box-shadow: 9999px 0 0 -5px;
  animation: dot-pulse 1.5s infinite linear;
  animation-delay: 0.25s;
  margin-left: 20px;
  background-color: var(--primary-color);
  color: var(--primary-color);
  top:0px !important
}

.Loaders::before,.Loaders:after{
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: var(--primary-color);
  color: var(--primary-color);
}
.Loaders::before{
  box-shadow: 9984px 0 0 -5px;
  animation: dot-pulse-before 1.5s infinite linear;
  animation-delay: 0s;
}
.Loaders::after {
  box-shadow: 10014px 0 0 -5px;
  animation: dot-pulse-after 1.5s infinite linear;
  animation-delay: 0.5s;
}
.loader-white{
    position: relative;
    left: -9999px;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: var(--white);
    color: var(--white);
    box-shadow: 9999px 0 0 -5px;
    animation: dot-pulse 1.5s infinite linear;
    animation-delay: 0.25s;
    top:10px;
    margin-left: 20px
  }
  .loader-white{
    background-color: var(--white);
    color: var(--white);
    top:0px !important
  }
  .loader-white::before,.loader-white:after{
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: var(--white);
    color: var(--white);
  }
  .loader-white::before{
    box-shadow: 9984px 0 0 -5px;
    animation: dot-pulse-before 1.5s infinite linear;
    animation-delay: 0s;
  }
  .loader-white::after {
    box-shadow: 10014px 0 0 -5px;
    animation: dot-pulse-after 1.5s infinite linear;
    animation-delay: 0.5s;
  }
@keyframes dot-pulse-before {
  0% {
    box-shadow: 9984px 0 0 -5px;
  }
  30% {
    box-shadow: 9984px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 9984px 0 0 -5px;
  }
}
@keyframes dot-pulse {
  0% {
    box-shadow: 9999px 0 0 -5px;
  }
  30% {
    box-shadow: 9999px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 9999px 0 0 -5px;
  }
}
@keyframes dot-pulse-after {
  0% {
    box-shadow: 10014px 0 0 -5px;
  }
  30% {
    box-shadow: 10014px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 10014px 0 0 -5px;
  }
}
.form-group a{
  color: #006736;
}
.skeleton-loader{
  margin-bottom: 0 !important;
}

.item-name:hover{
  background-color: #e2e5e8;
}
.load-section{
  position: relative;
  top: 50%;
}
.table-disable{
  opacity: 0.5 !important;
}
@media(max-width:1430px){
  .position-abs {
    position: relative;
    top: 0;
    right: 0;
  }
}
@media(max-width:991px){
  .mob-toggler {
    top: 10px;
    right: 15px;
    display: block;
    color: #212121 !important;
    font-size: 24px;
  }
  .m-header .show-view{
    top: 55px !important;
  }
}
